<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		id="modal-add-expense"
		ref="addNewExpenseModal"
		v-model="isOpen"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
		@ok="(e) => addNewExpenseModal(e, addDirectly)"
		@show="initNewExpenseModal"
		@cancel="delExpenseOnCancelModal"
		@close="delExpenseOnCancelModal"
		@hidden="delExpenseOnCancelModal"
		size="lg"
		modal-class="mui-animation"
		:fade="false"
	>
		<template #modal-header="{ close }">
			<div class="d-flex flex-row w-100">
				<div class="d-flex flex-row w-100">
					<h5>{{ expenseModalTitle(expenseType) }}</h5>
				</div>
				<div class="d-flex flex-row w-100">
					<div v-if="editData !== null" class="d-flex w-80 align-items-center justify-content-end w-100">
						<span v-if="!isFilmSingle && $has(editData.encoder)" class="border-encoded p-1">
							{{ FormMSG(33, 'Encoded by: ') }}<b>{{ editData.encoder.firstName }} {{ editData.encoder.name }}</b>
						</span>
					</div>
					<div :class="editData !== null ? 'w-20' : 'w-100'">
						<button class="btn-close" type="button" @click="delExpenseOnCancelModal()">×</button>
					</div>
				</div>
			</div>
		</template>
		<div v-if="isEditMode">
			<b-alert v-if="isRefusedExpense" variant="danger" show>
				<div>
					<b>{{ FormMSG(1, 'Refused for') }}:</b>
				</div>
				<div class="mt-1">{{ editData.comment }}</div>
			</b-alert>
		</div>
		<form ref="form" @submit.stop.prevent="submitNewExpenseModal">
			<div class="card card-border-blue-light px-4 py-3">
				<div>
					<b-alert :show="error && error.status === 509 ? true : false" variant="warning">
						<h4 class="alert-heading">{{ FormMSG(30, 'Informations') }}</h4>
						<div class="d-flex flex-row justify-content-start align-items-center w-100" style="gap: 8px">
							<div>
								<component :is="getLucideIcon('AlertTriangle')" color="#EA4E" :size="40" />
							</div>
							<div>
								{{
									FormMSG(
										34,
										'Sorry, cannot perfrom this action because you do not have any contract valid on this selected date, please contact your administrator.'
									)
								}}
							</div>
						</div>
					</b-alert>
				</div>
				<div v-if="newExpense.type === EXPENSE_TYPE.TRAVEL">
					<!-- mileage -->
					<CreateMileage
						:useComponent="useComponent"
						:form-type="newExpense.type"
						:edit-data="editData"
						:is-new="isNew"
						:is-submitted="isSubmitted"
						:user-id="userId"
						:transport-type-from-vehicule="transportTypeFromVehicule"
						@change="handleCreateMileage"
						@mileage:invalid="isInvalidateFields"
						@changeUser="handleSelectUser"
						@contractActive="contractActive"
					/>
					<b-row>
						<b-col>
							<b-alert :show="showAlertChangeTransportMean" variant="warning" dismissible @dismissed="showAlertChangeTransportMean = false">
								<component :is="getLucideIcon('AlertTriangle')" :size="16" />
								{{ FormMSG(485, 'The information of transport type has changed with vehicle information, please verify') }}
							</b-alert>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
								<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
									{{ FormMSG(168, 'Green details') }}
								</legend>
								<div class="pb-3">
									<co-two-clap
										ref="coTwoClapTicket"
										:parent-id="+currTicketRef"
										:entity-type="1"
										:for-create="!isEditMode"
										:external-carbon-data="carbonData"
										hide-location-menu
										hide-premise-menu
										hide-btn-reviewed
										disable-carbon-type-selector
										hide-distance-known-transport
										hide-transport-type-selector-transport
										hide-from-address-transport
										hide-to-address-transport
										hide-distance-transport
										get-my-vehicule-in-transport
										@co-two-clap:change="handleCoTwoClapChange"
									/>
								</div>
							</fieldset>
						</b-col>
					</b-row>
				</div>
				<div v-if="newExpense.type === EXPENSE_TYPE.TICKET">
					<!-- ticket -->
					<CreateTicket
						:userValue="user"
						:useComponent="useComponent"
						:form-type="newExpense.type"
						:edit-data="editData"
						:is-new="isNew"
						:expense-type="newExpense.type"
						:is-submitted="isSubmitted"
						@payload-refreshed="handlePayloadRefresh"
						@change="handleCreateTicketChange"
						@ticket:invalid="isInvalidateFields"
						@changeUser="handleSelectUser"
						@contractActive="contractActive"
					/>
					<b-row v-if="useGreenTable">
						<b-col>
							<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
								<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
									{{ FormMSG(168, 'Green details') }}
								</legend>
								<div class="pb-3">
									<co-two-clap
										ref="coTwoClapTicket"
										:parent-id="+currTicketRef"
										:entity-type="1"
										:for-create="!isEditMode"
										:external-carbon-data="carbonData"
										hide-location-menu
										hide-premise-menu
										hide-btn-reviewed
										@co-two-clap:change="handleCoTwoClapChange"
									/>
								</div>
							</fieldset>
						</b-col>
					</b-row>
				</div>
				<div v-if="newExpense.type === EXPENSE_TYPE.INVOICE">
					<!-- invoice -->
					<PoSelector
						:useComponent="useComponent"
						v-if="!isEditMode"
						v-show="currTicketRef === null"
						@selected="handlePoItemsSelected"
						@init-new-ref="handleNewRef"
						@changeUser="handleSelectUser"
						@contractActive="contractActive"
					/>
					<!-- v-if="isTicketFromPO" -->
					<CreateTicket
						:userValue="user"
						:useComponent="useComponent"
						v-if="isEditMode || openInvoiceModal"
						:form-type="newExpense.type"
						:edit-data="editData"
						:is-new="isNew"
						:expense-type="newExpense.type"
						:is-submitted="isSubmitted"
						@change="handleCreateTicketChange"
						@ticket:invalid="isInvalidateFields"
						@upload-image-loading:status="handleImageCurrentlyUploading"
						@changeUser="handleSelectUser"
						@contractActive="contractActive"
					/>
					<b-row v-if="isEditMode || openInvoiceModal">
						<b-col>
							<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
								<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
									{{ FormMSG(168, 'Green details') }}
								</legend>
								<div class="pb-3">
									<co-two-clap
										ref="coTwoClapTicket"
										:parent-id="+currTicketRef"
										:entity-type="1"
										:for-create="!isEditMode"
										:external-carbon-data="carbonData"
										hide-location-menu
										hide-premise-menu
										hide-btn-reviewed
										@co-two-clap:change="handleCoTwoClapChange"
									/>
								</div>
							</fieldset>
						</b-col>
					</b-row>
				</div>
			</div>
		</form>
		<template #modal-footer="{ ok, cancel }">
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button variant="light" @click="closeModal()">
				{{ FormMSG(998, 'Cancel') }}
			</b-button>
			<b-button
				v-if="useComponent"
				variant="success"
				:disabled="!isCanSubmit"
				@click="isImageCurrentlyUploading ? handleConfirmSubmitWithoutImage() : ok()"
			>
				{{ FormMSG(999, 'Save') }}
			</b-button>
			<b-button v-else variant="success" :disabled="!isCanSubmit" @click="isImageCurrentlyUploading ? handleConfirmSubmitWithoutImage() : ok()">
				{{ FormMSG(999, 'Save') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isNil, omit } from '@/shared/utils';
import CreateMileage from '@/components/ExpenseService/Mileage';
import CreateTicket from '@/components/ExpenseService/Ticket';
import PoSelector from '@/components/ExpenseService/Invoice';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { recalculateDistanceValueToKm } from '@/shared/google/helpers';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { INIT_DATA_CARBON, EXPENSE_TYPE } from '@/shared/constants';
import moment from 'moment';
import { store } from '@/store';
import * as _ from 'lodash';
import { getVehicule } from '@/cruds/vehicules.crud';

const defaultExpense = {
	department: 0,
	paiementType: 0,
	subCategory: 0,
	supplierName: '',
	supplierId: null,
	paiementTypeName: '',
	type: 0, // 0 Ticket - 1 Invoice - 2 KM
	description: '',
	date: `${moment(new Date()).format('YYYY-MM-DD')}T00:00:00Z`,
	amountTotal: 0,
	comment: '',
	km: 0,
	fromLocation: '',
	toLocation: '',
	validated: 0,
	categoryName: '',
	category: 0,
	kgCoTwo: 0,
	images: []
};

export default {
	name: 'NewExpenseFormComponent',
	components: { CreateMileage, CreateTicket, PoSelector, CoTwoClap },
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	props: {
		useComponent: {
			type: Boolean,
			default: false,
			required: false
		},
		value: {
			type: Boolean,
			default: false
		},
		expenseType: {
			type: Number,
			required: false,
			default: 2
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		isNew: {
			type: Boolean
		},
		userId: {
			type: Number,
			default: 0,
			required: false
		}
	},
	data() {
		return {
			newExpense: Object.assign({}, defaultExpense),
			// isIvoiceInited: false,
			invoiceSelected: null,
			isTicketFromPO: false,
			isCanSubmit: false,
			poItemsNotConsumedSelection: null,
			isSubmitted: false,
			isInvalid: false,
			isFromPoList: false,
			error: {
				status: null,
				message: ''
			},
			openInvoiceModal: false,
			isImageCurrentlyUploading: false,
			//	user: { value: '' }
			user: {},
			isNoContract: false,
			isActiveContract: false,
			messageNoContract: {},
			allowExpenseEntryOutsideContract: 0,
			messageAllowExpenseEntryOutsideContract: '',
			showAlert: false,
			showPopUp: false,
			addDataNoContract: false,
			addData: 'addData',
			addDirectly: 'addDirectly',
			submitCoTwoClap: false,
			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			EXPENSE_TYPE: EXPENSE_TYPE,

			showAlertChangeTransportMean: false,
			transportTypeFromVehicule: {}
		};
	},
	computed: {
		useGreenTable() {
			return store.state.myProject.useGreenTable;
		},
		...mapGetters({
			currentNewExpense: 'expenses/currentNewExpense'
		}),
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		},
		isEditMode() {
			return !isNil(this.editData);
		},
		/**
		 * @return {Boolean}
		 */
		isRefusedExpense() {
			if (isNil(this.editData)) return false;
			const status = parseInt(this.editData.validated);
			return status == 4 || status == 16;
		},
		currTicketRef: {
			get() {
				return this.$store.getters['expenses/currTicketRef'];
			},
			set(val) {
				this.setExpenseRef(val);
			}
		},
		setIsNoContract() {
			return this.isNoContract;
		},
		setAllowExpenseEntryOutsideContract() {
			return this.allowExpenseEntryOutsideContract;
		}
	},
	watch: {
		isOpen(val) {
			this.newExpense.type = this.expenseType;
		}
	},
	methods: {
		...mapActions({
			initNewExpense: 'expenses/initNewExpense',
			setNewExpense: 'expenses/setNewExpense',
			deleteExpense: 'expenses/deleteExpense',
			setExpenseRef: 'expenses/setExpenseRef',
			setNewInvoiceFromPO: 'expenses/setNewInvoiceFromPO',
			setNewInvoiceFromPoForSelectedPoItems: 'expenses/setNewInvoiceFromPoForSelectedPoItems'
		}),
		async handleCoTwoClapChange({ carbonData }) {
			if (this.newExpense.type === EXPENSE_TYPE.TRAVEL) {
				this.carbonData.reason = carbonData.reason;
				this.carbonData.numberOfVehicule = carbonData.numberOfVehicule;
				this.carbonData.vehiculeId = carbonData.vehiculeId;
				this.carbonData.number = carbonData.number;

				if (carbonData.vehiculeId > 0) {
					const resultVehicule = await getVehicule(carbonData.vehiculeId);

					this.transportTypeFromVehicule = {
						subCategory: resultVehicule.type,
						subType: resultVehicule.subType,
						additionalSubType: resultVehicule.additionalSubType
					};

					this.showAlertChangeTransportMean = true;

					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			} else {
				this.carbonData = carbonData;
			}
		},
		handleCoTwoClapEndSubmit() {
			this.newExpense = Object.assign({}, defaultExpense);
			// this.$emit('submited', true);
			this.invoiceSelected = null;
			this.currTicketRef = null;
			this.openInvoiceModal = false;
			this.hideModal();
			this.$emit('submited', true);
		},
		async initNewTicketRef() {
			if (!isNil(this.editData)) return;
			else {
				const carbon = {
					...INIT_DATA_CARBON,
					coTwoCategory: this.newExpense.type === EXPENSE_TYPE.TRAVEL ? 4 : 0,
					numberOfVehicule: this.newExpense.type === EXPENSE_TYPE.TRAVEL ? 1 : 0,
					reason: this.newExpense.type === EXPENSE_TYPE.TRAVEL ? 0 : 0
				};
				let result = await this.initNewExpense({
					...this.newExpense,
					carbon
				}).catch((e) => {
					const error = this.getErrorCode(e);

					if (!_.isNil(error)) {
						this.error = error;
						this.$emit('expense-service-main:error', this.error);
					}
				});

				return result;
			}
		},
		handlePayloadRefresh($event) {
			this.$emit('payload-refreshed', $event);
		},
		getErrorCode(error) {
			if (!_.isNil(error) && !_.isNil(error.graphQLErrors) && error.graphQLErrors.length > 0) {
				this.isCanSubmit = false;

				return {
					status: error.graphQLErrors[0].status,
					message: error.graphQLErrors[0].message.replace(new RegExp('[0-9]{3}\:', 'g'), '').trim()
				};
			}

			return null;
		},
		resetError() {
			this.error = {
				status: null,
				message: ''
			};
		},
		handleConfirmSubmitWithoutImage() {
			const msg = this.FormMSG(
				78686876,
				'Picture is still uploading on the server. If you continue, your picture will be lost. Do you want to save and exit?'
			);
			this.boxTwo = '';
			this.$bvModal
				.msgBoxConfirm(msg, {
					title: this.FormMSG(798797987, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(45456, 'Yes'),
					cancelTitle: this.FormMSG(12231213, 'Cancel'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true
				})
				.then((value) => {
					if (!value) return;
					this.ok();
				});
		},
		handleImageCurrentlyUploading(status) {
			this.isImageCurrentlyUploading = status;
		},
		/**
		 * gen title of the modal from expense type
		 *
		 * @param {Number} i - expenseType
		 * @return {String}
		 */
		expenseModalTitle(i) {
			/** @type {String} t */
			const t = !isNil(this.editData) ? this.FormMSG(23, 'Edit') : this.FormMSG(24, 'Add');

			/** @type {Object} titlesObj */
			const titlesObj = {
				0: this.FormMSG(11, 'Ticket'),
				1: this.FormMSG(12, 'Invoice'),
				2: this.FormMSG(13, 'Mileage'),
				3: this.FormMSG(14, 'Create one ticket from pictures'),
				4: this.FormMSG(15, 'Create multiple ticket from pictures (one picture/ticket)')
			};
			return `${t} ${titlesObj[i]}`;
		},
		async delExpenseOnCancelModal() {
			const ref = this.currTicketRef;
			this.resetError();
			this.$emit('input', false);
			if (!isNil(this.editData)) this.currTicketRef = null;
			if (!isNil(ref) && isNil(this.editData)) await this.deleteExpense(ref);
			if (this.isEditMode) {
				this.isCanSubmit = true;
			}
			this.openInvoiceModal = false;

			this.carbonData = _.cloneDeep(INIT_DATA_CARBON);

			this.$emit('expense-form-component:close', true);
		},
		closeModal() {
			this.delExpenseOnCancelModal();
			this.openInvoiceModal = false;
			this.user = {};
		},
		submitNewExpenseModal() {
			this.$nextTick(() => this.$bvModal.hide('modal-prevent-closing'));
		},
		async handleNewRef(payload) {
			if (payload) {
				this.newExpense.type = EXPENSE_TYPE.INVOICE; // 1
				await this.initNewTicketRef();
				this.isFromPoList = false;
				// this.showToastError();
				this.openInvoiceModal = true;
			} else {
				this.isFromPoList = true;
			}
			// this.isIvoiceInited = true;
		},
		async initNewExpenseModal() {
			if (this.isEditMode) {
				this.newExpense.validated = this.editData.validated;
				this.carbonData = this.editData.carbon;
				this.setExpenseRef(+this.editData.id);
			} else {
				if (this.newExpense.type !== EXPENSE_TYPE.INVOICE) {
					// 1
					await this.initNewTicketRef();
					// this.showToastError();
					if (!_.isNil(this.currentNewExpense)) {
						this.carbonData = this.currentNewExpense.carbon;
					}
				}
			}
		},
		asyncIsinvalid() {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					let isInvalidTicket = false;

					if (this.useGreenTable) {
						isInvalidTicket = this.$refs['coTwoClapTicket'].isInvalid();
					}

					if (this.isInvalid === false && isInvalidTicket === false) {
						resolve(this.isInvalid);
					}
					// resolve(this.isInvalid);
					reject(false);
				}, 150);
			});
		},
		async addNewExpenseModal(e, action) {
			e.preventDefault();
			this.isSubmitted = true;
			// this.submitCoTwoClap = true;
			this.addOutsideContract(e);
			if (this.addDataNoContract && action === this.addData) {
				await this.actionAddUpd(e);
				this.addDataNoContract = false;
				// this.hideModal();
				return;
			} else {
				if (this.showAlert === false) {
					await this.actionAddUpd(e);
					this.addDataNoContract = false;
					// this.hideModal();
					return;
				}
			}
		},
		async actionAddUpd(e) {
			if (this.isFromPoList && this.newExpense.type === EXPENSE_TYPE.INVOICE) {
				// 1
				if (isNil(this.poItemsNotConsumedSelection) || this.poItemsNotConsumedSelection.poItems.length === 0) return;
				await this.setNewInvoiceFromPoForSelectedPoItems(this.poItemsNotConsumedSelection);
				// this.$emit('submited', true);
				this.invoiceSelected = null;
				this.currTicketRef = null;
			} else {
				this.asyncIsinvalid().then(async (isinvalid) => {
					if (!isinvalid) {
						const _E = this.newExpense;
						// console.log({ newExpense: this.newExpense });
						const id = isNil(this.editData) ? this.currTicketRef : +this.editData.id;
						const omitByType = {
							2: ['categoryName', 'images'],
							1: ['km', 'categoryName', 'fromLocation', 'toLocation']
						};

						let omits = ['typeVat'];
						if (!isNil(omitByType[_E.type])) omits.push(...omitByType[_E.type]);

						const expenseId = isNil(id) ? +_E.id : +id;

						let payload = {
							expense: _.omit(_E, omits), // omit(omits, _E),
							id: isNaN(expenseId) ? 0 : expenseId,
							userId: +this.user,
							projectFlagItemId: this.newExpense.flagItemId
						};

						if (payload.expense.validated === 4 || payload.expense.validated === 16) {
							payload.expense.validated = 0;
						}

						if (payload.expense.type === EXPENSE_TYPE.TRAVEL) payload.expense.km = recalculateDistanceValueToKm(payload.expense.km); // 2
						payload.expense = {
							...payload.expense,
							carbon: {
								...this.carbonData,
								carbonId: _.isNil(this.editData) ? +this.currentNewExpense.carbon.id : +this.carbonData.id
							}
						};

						let payloadValide = { ...payload, userId: +this.user.value };

						await this.setNewExpense(payloadValide)
							.then(() => {
								this.newExpense = Object.assign({}, defaultExpense);
								this.invoiceSelected = null;
								this.currTicketRef = null;
								this.openInvoiceModal = false;

								this.carbonData = _.cloneDeep(INIT_DATA_CARBON);

								this.hideModal();
								this.$emit('submited', true);
							})
							.catch(async (error) => {
								this.resetError();
								const ref = this.currTicketRef;
								this.$emit('input', false);
								if (!isNil(this.editData)) this.currTicketRef = null;
								if (!isNil(ref) && isNil(this.editData)) {
									await this.deleteExpense(ref);

									let message = this.FormMSG(
										34,
										'Sorry, cannot perfrom this action because you do not have any contract valid on this selected date, please contact your administrator.'
									);

									this.createToastForMobile(this.FormMSG(35, 'Error'), message, '', 'danger');

									return;
								}

								const e = this.getErrorCode(error);

								if (!_.isNil(e)) {
									this.error = e;
									this.$emit('expense-service-main:error', this.error);
								}
							});
					}
				});
			}
		},
		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},
		/**
		 * @param {Object} ticket
		 */
		handleSelectUser(value) {
			this.user = {
				...this.user,
				value: +value
			};
			return this.user;
		},
		handleCreateTicketChange(ticket, isValid) {
			this.newExpense = Object.assign(this.newExpense, ticket);
			let canSave = false;
			if (this.newExpense.amountTotal > 0) {
				canSave = parseFloat(this.newExpense.amountTotal) > parseFloat(this.newExpense.amountVat) && !isValid;
			} else {
				canSave = parseFloat(this.newExpense.amountTotal) !== 0 && !isValid;
			}
			this.isCanSubmit = canSave;
		},
		/**
		 * @param {Object} ticket
		 */
		handleCreateMileage(mileage, isValid) {
			this.newExpense = Object.assign(this.newExpense, mileage);

			this.carbonData.type = mileage.subCategory;
			this.carbonData.subType = mileage.subType;
			this.carbonData.additionalSubType = mileage.additionalSubType;
			this.carbonData.km = mileage.km;

			this.isCanSubmit = this.newExpense.km > 0 && !isValid;
		},
		/**
		 * @param {!Object} change
		 */
		handleKlippaOneTicketFromPicChange(change) {
			this.newExpense = Object.assign(this.newExpense, change);
		},
		/**
		 * @param {Object} options - {poId, poItemsIds}
		 */
		handlePoItemsSelected(options) {
			const { poId, poItemsIds } = options;
			this.poItemsNotConsumedSelection = {
				id: poId,
				poItems: poItemsIds
			};
			if (this.poItemsNotConsumedSelection.poItems.length > 0) {
				this.isCanSubmit = true;
			} else {
				this.isCanSubmit = false;
			}
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		contractActive(data) {
			const { allowExpenseEntryOutsideContract, contractActive, messageAllowExpenseEntryOutsideContract, showAlert, showPopUp } = data;
			this.isSubmitted = false;
			this.allowExpenseEntryOutsideContract = allowExpenseEntryOutsideContract;
			this.messageAllowExpenseEntryOutsideContract = messageAllowExpenseEntryOutsideContract;
			this.showAlert = showAlert;
			this.isActiveContract = contractActive;
			this.showPopUp = showPopUp;
		},
		hideModal() {
			this.showPopUp = false;
			this.isSubmitted = false;
			this.submitCoTwoClap = false;
			this.addDataNoContract = false;
		},
		addOutsideContract(e, action) {
			this.showPopUp = this.allowExpenseEntryOutsideContract === 1 ? true : false;

			if (this.showPopUp === true && action === this.addData && this.allowExpenseEntryOutsideContract === 1) {
				this.addDataNoContract = true;
			}
			if (action === this.addData) {
				this.addNewExpenseModal(e, this.addData);
			}
		}
	}
};
</script>
<style lang="scss">
.btn-close {
	background-color: transparent;
	border: 0;
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}

.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}

//#modal-add-expense label,
//#modal-add-expense legend {
//	font-size: 14px !important;
//	margin-bottom: 0.5rem !important;
//}
</style>
