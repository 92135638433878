var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "addNewExpenseModal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "modal-add-expense",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        size: "lg",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: (e) => _vm.addNewExpenseModal(e, _vm.addDirectly),
        show: _vm.initNewExpenseModal,
        cancel: _vm.delExpenseOnCancelModal,
        close: _vm.delExpenseOnCancelModal,
        hidden: _vm.delExpenseOnCancelModal,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c("div", { staticClass: "d-flex flex-row w-100" }, [
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.expenseModalTitle(_vm.expenseType))),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _vm.editData !== null
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex w-80 align-items-center justify-content-end w-100",
                        },
                        [
                          !_vm.isFilmSingle && _vm.$has(_vm.editData.encoder)
                            ? _c(
                                "span",
                                { staticClass: "border-encoded p-1" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(33, "Encoded by: "))
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.editData.encoder.firstName) +
                                        " " +
                                        _vm._s(_vm.editData.encoder.name)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { class: _vm.editData !== null ? "w-20" : "w-100" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-close",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.delExpenseOnCancelModal()
                            },
                          },
                        },
                        [_vm._v("×")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(998, "Cancel")) + "\n\t\t"
                  ),
                ]
              ),
              _vm.useComponent
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "success", disabled: !_vm.isCanSubmit },
                      on: {
                        click: function ($event) {
                          _vm.isImageCurrentlyUploading
                            ? _vm.handleConfirmSubmitWithoutImage()
                            : ok()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" + _vm._s(_vm.FormMSG(999, "Save")) + "\n\t\t"
                      ),
                    ]
                  )
                : _c(
                    "b-button",
                    {
                      attrs: { variant: "success", disabled: !_vm.isCanSubmit },
                      on: {
                        click: function ($event) {
                          _vm.isImageCurrentlyUploading
                            ? _vm.handleConfirmSubmitWithoutImage()
                            : ok()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" + _vm._s(_vm.FormMSG(999, "Save")) + "\n\t\t"
                      ),
                    ]
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm.isEditMode
        ? _c(
            "div",
            [
              _vm.isRefusedExpense
                ? _c("b-alert", { attrs: { variant: "danger", show: "" } }, [
                    _c("div", [
                      _c("b", [
                        _vm._v(_vm._s(_vm.FormMSG(1, "Refused for")) + ":"),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-1" }, [
                      _vm._v(_vm._s(_vm.editData.comment)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.submitNewExpenseModal.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "card card-border-blue-light px-4 py-3" }, [
            _c(
              "div",
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      show:
                        _vm.error && _vm.error.status === 509 ? true : false,
                      variant: "warning",
                    },
                  },
                  [
                    _c("h4", { staticClass: "alert-heading" }, [
                      _vm._v(_vm._s(_vm.FormMSG(30, "Informations"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-start align-items-center w-100",
                        staticStyle: { gap: "8px" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(_vm.getLucideIcon("AlertTriangle"), {
                              tag: "component",
                              attrs: { color: "#EA4E", size: 40 },
                            }),
                          ],
                          1
                        ),
                        _c("div", [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  34,
                                  "Sorry, cannot perfrom this action because you do not have any contract valid on this selected date, please contact your administrator."
                                )
                              ) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.newExpense.type === _vm.EXPENSE_TYPE.TRAVEL
              ? _c(
                  "div",
                  [
                    _c("CreateMileage", {
                      attrs: {
                        useComponent: _vm.useComponent,
                        "form-type": _vm.newExpense.type,
                        "edit-data": _vm.editData,
                        "is-new": _vm.isNew,
                        "is-submitted": _vm.isSubmitted,
                        "user-id": _vm.userId,
                        "transport-type-from-vehicule":
                          _vm.transportTypeFromVehicule,
                      },
                      on: {
                        change: _vm.handleCreateMileage,
                        "mileage:invalid": _vm.isInvalidateFields,
                        changeUser: _vm.handleSelectUser,
                        contractActive: _vm.contractActive,
                      },
                    }),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-alert",
                              {
                                attrs: {
                                  show: _vm.showAlertChangeTransportMean,
                                  variant: "warning",
                                  dismissible: "",
                                },
                                on: {
                                  dismissed: function ($event) {
                                    _vm.showAlertChangeTransportMean = false
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon("AlertTriangle"), {
                                  tag: "component",
                                  attrs: { size: 16 },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        485,
                                        "The information of transport type has changed with vehicle information, please verify"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "fieldset",
                            {
                              staticClass: "my-0 py-0 pb-0",
                              class: `${
                                _vm.$screen.width <= 576
                                  ? "card-inside"
                                  : "scheduler-border"
                              }`,
                            },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass: "text-color-rhapsody-in-blue-2",
                                  class: `${
                                    _vm.$screen.width <= 576
                                      ? "card-inside"
                                      : "scheduler-border"
                                  }`,
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(168, "Green details")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "pb-3" },
                                [
                                  _c("co-two-clap", {
                                    ref: "coTwoClapTicket",
                                    attrs: {
                                      "parent-id": +_vm.currTicketRef,
                                      "entity-type": 1,
                                      "for-create": !_vm.isEditMode,
                                      "external-carbon-data": _vm.carbonData,
                                      "hide-location-menu": "",
                                      "hide-premise-menu": "",
                                      "hide-btn-reviewed": "",
                                      "disable-carbon-type-selector": "",
                                      "hide-distance-known-transport": "",
                                      "hide-transport-type-selector-transport":
                                        "",
                                      "hide-from-address-transport": "",
                                      "hide-to-address-transport": "",
                                      "hide-distance-transport": "",
                                      "get-my-vehicule-in-transport": "",
                                    },
                                    on: {
                                      "co-two-clap:change":
                                        _vm.handleCoTwoClapChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.newExpense.type === _vm.EXPENSE_TYPE.TICKET
              ? _c(
                  "div",
                  [
                    _c("CreateTicket", {
                      attrs: {
                        userValue: _vm.user,
                        useComponent: _vm.useComponent,
                        "form-type": _vm.newExpense.type,
                        "edit-data": _vm.editData,
                        "is-new": _vm.isNew,
                        "expense-type": _vm.newExpense.type,
                        "is-submitted": _vm.isSubmitted,
                      },
                      on: {
                        "payload-refreshed": _vm.handlePayloadRefresh,
                        change: _vm.handleCreateTicketChange,
                        "ticket:invalid": _vm.isInvalidateFields,
                        changeUser: _vm.handleSelectUser,
                        contractActive: _vm.contractActive,
                      },
                    }),
                    _vm.useGreenTable
                      ? _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "my-0 py-0 pb-0",
                                  class: `${
                                    _vm.$screen.width <= 576
                                      ? "card-inside"
                                      : "scheduler-border"
                                  }`,
                                },
                                [
                                  _c(
                                    "legend",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue-2",
                                      class: `${
                                        _vm.$screen.width <= 576
                                          ? "card-inside"
                                          : "scheduler-border"
                                      }`,
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(168, "Green details")
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "pb-3" },
                                    [
                                      _c("co-two-clap", {
                                        ref: "coTwoClapTicket",
                                        attrs: {
                                          "parent-id": +_vm.currTicketRef,
                                          "entity-type": 1,
                                          "for-create": !_vm.isEditMode,
                                          "external-carbon-data":
                                            _vm.carbonData,
                                          "hide-location-menu": "",
                                          "hide-premise-menu": "",
                                          "hide-btn-reviewed": "",
                                        },
                                        on: {
                                          "co-two-clap:change":
                                            _vm.handleCoTwoClapChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.newExpense.type === _vm.EXPENSE_TYPE.INVOICE
              ? _c(
                  "div",
                  [
                    !_vm.isEditMode
                      ? _c("PoSelector", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currTicketRef === null,
                              expression: "currTicketRef === null",
                            },
                          ],
                          attrs: { useComponent: _vm.useComponent },
                          on: {
                            selected: _vm.handlePoItemsSelected,
                            "init-new-ref": _vm.handleNewRef,
                            changeUser: _vm.handleSelectUser,
                            contractActive: _vm.contractActive,
                          },
                        })
                      : _vm._e(),
                    _vm.isEditMode || _vm.openInvoiceModal
                      ? _c("CreateTicket", {
                          attrs: {
                            userValue: _vm.user,
                            useComponent: _vm.useComponent,
                            "form-type": _vm.newExpense.type,
                            "edit-data": _vm.editData,
                            "is-new": _vm.isNew,
                            "expense-type": _vm.newExpense.type,
                            "is-submitted": _vm.isSubmitted,
                          },
                          on: {
                            change: _vm.handleCreateTicketChange,
                            "ticket:invalid": _vm.isInvalidateFields,
                            "upload-image-loading:status":
                              _vm.handleImageCurrentlyUploading,
                            changeUser: _vm.handleSelectUser,
                            contractActive: _vm.contractActive,
                          },
                        })
                      : _vm._e(),
                    _vm.isEditMode || _vm.openInvoiceModal
                      ? _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "my-0 py-0 pb-0",
                                  class: `${
                                    _vm.$screen.width <= 576
                                      ? "card-inside"
                                      : "scheduler-border"
                                  }`,
                                },
                                [
                                  _c(
                                    "legend",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue-2",
                                      class: `${
                                        _vm.$screen.width <= 576
                                          ? "card-inside"
                                          : "scheduler-border"
                                      }`,
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(168, "Green details")
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "pb-3" },
                                    [
                                      _c("co-two-clap", {
                                        ref: "coTwoClapTicket",
                                        attrs: {
                                          "parent-id": +_vm.currTicketRef,
                                          "entity-type": 1,
                                          "for-create": !_vm.isEditMode,
                                          "external-carbon-data":
                                            _vm.carbonData,
                                          "hide-location-menu": "",
                                          "hide-premise-menu": "",
                                          "hide-btn-reviewed": "",
                                        },
                                        on: {
                                          "co-two-clap:change":
                                            _vm.handleCoTwoClapChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }